<template>
  <div :class="{ 'is-loading': isSaving }">
    <CCard>
      <CCardHeader>
        <h2>New Client Intake</h2>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol>
            <CInput
              v-model="projectName"
              label="Project Name"
              :invalid-feedback="projectNameError.invalidFeedback"
              :is-valid="projectNameError.state"
            />
          </CCol>
          <CCol>
            <DatePicker
              v-model="defaultStartDate"
              label="Start Date"
              :invalid-feedback="defaultStartDateError.invalidFeedback"
              :is-valid="defaultStartDateError.state"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput
              v-model="clientName"
              label="Client Name"
              :invalid-feedback="clientNameError.invalidFeedback"
              :is-valid="clientNameError.state"
            />
          </CCol>
          <CCol>
            <CInput
              v-model="clientCode"
              label="Client Code"
              :invalid-feedback="clientCodeError.invalidFeedback"
              :is-valid="clientCodeError.state"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CSelect
              label="Tax Engine"
              :value.sync="taxEngine"
              :options="taxEngines"
              placeholder="Choose"
              :invalid-feedback="taxEngineError.invalidFeedback"
              :is-valid="taxEngineError.state"
              required
            />
          </CCol>
          <CCol v-if="allowCustomOnboarding">
            <CSelect
              label="Onboarding Type"
              :value.sync="onboardingType"
              :options="onboardingTypes"
              required
              :invalid-feedback="onboardingTypeError.invalidFeedback"
              :is-valid="onboardingTypeError.state"
            />
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CButtonToolbar>
          <CButton
            color="primary"
            class="mr-2"
            :disabled="!canSubmit"
            @click="createClient"
            >Create</CButton
          >
          <CButton color="secondary" @click="$router.back()">Cancel</CButton>
        </CButtonToolbar>
      </CCardFooter>
    </CCard>
  </div>
</template>
<script>
import { formatDate } from '@/utils/format';
import DatePicker from '@/components/DatePicker';
import { formsMixin, mapFormProps } from '@/mixins';
import {
  VUE_APP_ALLOW_CUSTOM_ONBOARDING,
  VUE_APP_ALLOW_VTX,
  VUE_APP_ALLOW_SBX,
  VUE_APP_ALLOW_AVA,
  VUE_APP_ALLOW_GTD,
} from '@/utils/env';

const TAX_ENGINES = [
  {
    label: 'ONESOURCE',
    value: 'SBX',
    enabled: VUE_APP_ALLOW_SBX,
  },
  {
    label: 'Vertex',
    value: 'VTX',
    enabled: VUE_APP_ALLOW_VTX,
  },
  {
    label: 'Avalara',
    value: 'AVA',
    enabled: VUE_APP_ALLOW_AVA,
  },
  {
    label: 'Sovos GTD',
    value: 'GTD',
    enabled: VUE_APP_ALLOW_GTD,
  },
];

const ONBOARDING_TYPES = [
  {
    label: 'Standard',
    value: 'STD',
    enabled: true,
  },
  {
    label: 'Rapid Prototype',
    value: 'SALES',
    enabled: VUE_APP_ALLOW_CUSTOM_ONBOARDING,
  },
  {
    label: 'ONESOURCE Import',
    value: 'SBX_IMPORT',
    enabled: VUE_APP_ALLOW_CUSTOM_ONBOARDING,
  },
  {
    label: 'Vertex Import',
    value: 'VTX_IMPORT',
    enabled: VUE_APP_ALLOW_CUSTOM_ONBOARDING,
  },
];

export default {
  name: 'CreateClient',
  components: {
    DatePicker,
  },
  mixins: [formsMixin],
  data() {
    return {
      allowCustomOnboarding: VUE_APP_ALLOW_CUSTOM_ONBOARDING,
      clientDetails: {
        projectName: null,
        defaultStartDate: new Date(),
        clientName: null,
        clientCode: null,
        taxEngine: null,
        onboardingType: null,
      },
      requiredFields: [
        'projectName',
        'defaultStartDate',
        'clientName',
        'clientCode',
        'taxEngine',
      ],
    };
  },
  computed: {
    ...mapFormProps(
      [
        'projectName',
        'clientName',
        'clientCode',
        'defaultStartDate',
        'taxEngine',
        'onboardingType',
      ],
      {
        object: 'clientDetails',
      }
    ),
    canSubmit() {
      return !this.hasErrors && this.hasRequiredFields;
    },
    taxEngines() {
      return TAX_ENGINES.filter((t) => t.enabled).map((o) => {
        return {
          value: o.value,
          label: o.label,
        };
      });
    },
    onboardingTypes() {
      return ONBOARDING_TYPES.filter((o) => o.enabled).map((o) => {
        return {
          value: o.value,
          label: o.label,
        };
      });
    },
  },
  methods: {
    formatDate,
    createClient() {
      this.isSaving = true;
      let client = {
        projectName: this.projectName,
        defaultStartDate: this.formatDate(this.defaultStartDate, 'yyyy-MM-dd'),
        clientName: this.clientName,
        clientCode: this.clientCode,
        taxEngine: this.taxEngine,
        onboardingType: this.onboardingType,
      };

      if (!VUE_APP_ALLOW_CUSTOM_ONBOARDING) {
        client.onboardingType = 'STD';
      }

      const self = this;

      this.$store
        .dispatch('clients/create', client)
        .then((response) => {
          let msg = 'The client has been created successfully!';
          this.showSuccess(msg).then(() => {
            this.$router.push({
              name: 'view-client',
              params: { name: response.clientCode },
            });
          });
        })
        .catch((err) => {
          self.handleApiError(err);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
};
</script>
